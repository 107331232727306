.confirm-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-buttons > * {
  margin: 0 .5rem;
  font-weight: bold;
}

.confirm-message {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 2rem;
}