.row-buttons {
  display: flex;
  justify-content: flex-end;
}

.row-buttons > button {
  margin-left: 1rem;
}

.no-display {
  display: none;
}

.label {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.weekSelector :global .rdtPicker {
  margin-top: 2.5rem;
}

.just-table :global .controls,
.just-table :global .pagination-wrapper {
  display: none !important;
}