.cultivo-tr {
  font-size: 0.85em;
}

.cultivo-tr td {
  padding: 0.25rem 0.75rem;
}

.trabajador-actividad-list {
  margin: 0;
  padding: 0;
  padding-left: 1.25rem;
  line-height: 1;
}

.trabajador-actividad-list li {
  padding: 0.25rem 0;
}

.trabajador-actividad-list li span:last-child {
  font-size: 0.85em;
}