.empty {
  background-color: #f5f5f5;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #777;
  border-radius: 0.25rem;
}

.empty p {
  margin-bottom: 0;
  font-weight: bold;
}

.list {
  list-style: none;
  margin: 0;
  background-color: #f5f5f5;
  border-radius: 0.25rem;
  padding: 1rem 0.75rem;
  max-height: 70vh;
  overflow-y: auto;
}

.special {
  max-height: 60vh;
}

.list li {
  background-color: #fff;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.25rem;
}

.list li:last-child {
  margin-bottom: 0;
}

.list li:hover {
  background-color: #f5f5f5;
}

.li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form {
}

.form label {
  font-weight: bold;
}
