.button-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.results-container {
  padding: 1.5rem 0;
}

.empty {
  background-color: #f5f5f5;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #777;
  border-radius: 0.25rem;
}

.empty p {
  margin-bottom: 0;
  font-weight: bold;
}

.wafoform-nomargin {
  margin-bottom: 0;
}

.well {
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 0.25rem;
  margin-top: 1rem;
}
