$turquesaColor: #00A99D;
$titlesColor:#050057;
$blueColor:#136DF4;
$editColor:#00B14B;
$deletColor:#EC2020;
$violet:#9B51E0;
$green:#77C25E;
$greenAgrofesa:#226729;
$colorGray: #226729;
$colorBackgroundPrimary:#9B51E0;
$colorPrimaryLow:#77C25E;
//$greenAgrofesa:#226729;
