.report-alert {
  position: fixed;
  top: 5%;
  right: 2.5%;
  z-index: 1000;
  width: 30%;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.50);
}

.report-alert .alert {
  margin-bottom: 0;
}